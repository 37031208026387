<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="Datasource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
        <br>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'NewDeliveryOrderDashboardGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dashboardData'],
    computed: {
        Datasource(){
            var data = { data : this.$props.dashboardData };
            
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: function (e) {
                        e.success(data);
                    },
                },
                schema: {
                    data: function (response) {
                        if(response.data == null){
                            return [];
                        }else{
                            return response.data;
                        }
                    },
                    total: function (response) {
                        if(response.data == null){
                            return 0;
                        }else{
                            return response.data.length;
                        }
                    }
                },
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "delivery_order_number", title: "No. Delivery", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "purchase_order_number", title: "No. PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "delivery_order_date", title: "Tanggal Delivery", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(delivery_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "customer_name", title: "Customer", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "expedition_name", title: "Ekspedisi", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Catatan", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },    
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    async mounted() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$router.push({ name: 'Delivery Order Form', params: { formtype:'Edit', id:dataItem.delivery_order_id, view:true } });
        })
    },
    methods: {
        columnButton(){
            return `<button type="button" class="btn btn-info btn-sm rounded" id="ViewButton">
                        <i class="fa fa-eye"></i> </span>
                    </button>`;
        },
    }
}
</script>
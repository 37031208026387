<template>
    <div class="container">
        <CRow>
            <CCol v-for="(item, i) in Items" :key="i" class="col-auto">
                <div class="card mx-auto" style="width: 25rem; height: 10rem; border-radius: 5px;">
                    <div class="card-body" v-bind:style="{ backgroundColor: item['PrimaryColor'] }">
                        <div class="row px-3">
                        <div class="col-9" style="color:white; width:100%">
                            <b><h2>{{item['Name']}}</h2></b>
                            <b><h4 style="float: right;">{{item['Data']}}</h4></b>
                            <br>
                        </div>
                        <div class="col-3" style="color:white; font-size:5em;">
                            <i v-bind:class="[{staticClass: true}, item['Icon']]" style="float:right"></i>
                        </div>
                        </div>
                    </div>
                    <div class="card-footer text-center p-0" v-bind:style="{ backgroundColor: item['SecondaryColor'] }">
                        <button class="btn" type="button" style="width:100%; color:white" id="btnAttendance" @click.prevent="linkModule(item['Url'])"><b>More Info</b>&nbsp;&nbsp;<i class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>
  
<script>
export default {
    name: 'DashboardCard',
    props: ['dashboardData'],
    data: function () {
        return  {
            Items: this.$props.dashboardData
        };
    },
    async mounted() {
        
    },
    methods: {
        linkModule(url){
            this.$router.push(url);
        }
    }
}
</script>